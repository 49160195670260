<template>
  <div class="main">
    <Jumbotron
      title="Contatti"
      img="luca-garlaschelli-mano.jpg"
    />

    <div class="container contatti">
      <div class="row">
        <div class="col-md-6">
          SKYLINE Management, Press & Booking Agency <br>
          Rossella Del Grande<br>
          ++39 345 8861387<br>
          ++39  02 58110517<br>
          Twitter: @rossellaskyline<br>
          milani.gres@tiscali.it<br>
        </div>
        <div class="col-md-6">
          Luca Garlaschelli <br>
          +393386423424 <br>
          garlajazz@gmail.com <br>
        </div>
      </div>
    </div>
    

  </div>

  
</template>

<script>
import Jumbotron from '@/components/Jumbotron';
export default {
  name: 'About',
  components:{
    Jumbotron
  }
}
</script>

<style lang="scss" scoped>
.contatti{
  margin-top: 80px;
}
.main{
  margin: 45px 0;
}
.bio{
  text-align: justify;
  margin-top:20px
}
hr{
  color: white;
}
</style>